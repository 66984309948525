const paddingStep = 8;

export const paddingFunction = (amount = 1): number => amount * paddingStep;

const ThemeColors = {
	orange: '#FCA650',
	green: '#00e674',
	fonts: {
		headings: '#000',
		body: '#646464'
	},
	gray: '#BDBDBD'
};

export default {
	padding: (amount = 1, sides?: number): string =>
		`${paddingFunction(amount)}px ${sides ? `${paddingFunction(sides)}px` : ''}`,
	borderRadius: '6px',
	colors: {
		primary: ThemeColors.orange,
		...ThemeColors
	}
};
