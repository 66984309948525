import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

type LabelAppearance = 'normal' | 'success' | 'error';

export const LabelStyled = styled.div<{ appearance: LabelAppearance }>`
	padding: ${(props): string => props.theme.padding(0.5) + ' ' + props.theme.padding(1)};
	font-size: 14px;
	font-weight: 700;
	color: #fff;
	background: ${(props): string => props.theme.colors.primary};
	display: inline-block;
	border-radius: 12px;

	${(props): false | FlattenSimpleInterpolation =>
		props.appearance === 'success' &&
		css`
			background: ${props.theme.colors.green};
		`}
`;

interface LabelProps {
	children: React.ReactNode;
	appearance?: LabelAppearance;
	className?: string;
}

export const Label = ({ children, appearance, ...props }: LabelProps): JSX.Element => (
	<LabelStyled appearance={appearance || 'normal'} {...props}>
		{children}
	</LabelStyled>
);
