import firebase from 'firebase/app';

export interface IGift {
	created: string;
	fromUserId: string;
	redeemed: boolean | null;
	redeemedUserId: string;
	documentId?: string;
}

export class Gift implements IGift {
	created: string;
	fromUserId: string;
	redeemed: boolean | null;
	redeemedUserId: string;
	documentId?: string;

	constructor(
		created: string,
		fromUserId: string,
		redeemed: boolean | null,
		redeemedUserId: string,
		documentId?: string
	) {
		this.created = created;
		this.fromUserId = fromUserId;
		this.redeemed = redeemed;
		this.redeemedUserId = redeemedUserId;
		this.documentId = documentId;
	}

	static fromSnapshot(data: firebase.firestore.DocumentData, documentId: string): Gift {
		const gift = { ...data } as Gift;

		return new Gift(
			gift.created,
			gift.fromUserId,
			gift.redeemed,
			gift.redeemedUserId,
			documentId
		);
	}
}
