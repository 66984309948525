import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

type InputAppearance = 'normal' | 'inverted';

interface FormInputProps {
	name: string;
	type?: string;
	placeholder?: string;
	value?: string;
	label?: string;
	width?: string;
	appearance?: InputAppearance;
	onChange?(event: HTMLElementEvent<HTMLInputElement>): void;
	onKeyDown?(event: KeyboardEvent): void;
}

const FormLabel = styled.label`
	font-size: 12px;
	color: #fff;
	display: block;
	margin-bottom: ${(props): string => props.theme.padding(1)};
`;

// Getting some stupid errors in render
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const StyledInput = styled.input<any>`
	background: transparent;
	width: ${(props): string => props.width!};
	border-radius: ${(props): string => props.theme.borderRadius};
	padding: 12px;
	outline: none;
	border-width: 1px;
	border-style: solid;

	${(props): FlattenSimpleInterpolation => {
		switch (props.appearance) {
			case 'normal': {
				return css`
					color: #000;
					border-color: #000;
				`;
			}
			case 'inverted': {
				return css`
					color: #fff;
					border-color: #fff;
				`;
			}
			default:
				throw new Error('Invalid appearance for Input');
		}
	}}
`;

const FormInput = ({
	name,
	label,
	width = 'auto',
	appearance = 'normal',
	...props
}: FormInputProps): JSX.Element => {
	return (
		<>
			{label && <FormLabel htmlFor={name}>{label}</FormLabel>}
			<>
				<StyledInput
					name={name}
					id={name}
					appearance={appearance}
					width={width}
					{...props}
				/>
			</>
		</>
	);
};

export default FormInput;
