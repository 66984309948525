import React from 'react';
import styled, { css } from 'styled-components';
import { IAddress } from '../../models/Address';

interface AddressDisplayProps {
	address: IAddress;
	padding?: boolean;
	showTitles?: boolean;
	border?: boolean;
	isLabel?: boolean;
}

const AddressContainer = styled.div<Pick<AddressDisplayProps, 'padding' | 'border' | 'isLabel'>>`
	border-radius: 3px;

	${props =>
		props.border &&
		css`
			border: 1px dashed #e5e5e5;
		`};
	${props =>
		props.padding &&
		css`
			padding: ${props.theme.padding(1)};
		`};

	> div {
		> p {
			margin: 0;
			font-size: 15px;
			line-height: 24px;

			${props =>
				props.isLabel &&
				css`
					font-size: 15pt;
					line-height: 26px;
				`}
			span {
				font-size: 11px;
				color: #666;
			}
		}
	}
`;

const AddressLineItem = ({
	title,
	showTitles,
	info
}: {
	title: string;
	showTitles: boolean;
	info: string;
}): JSX.Element => (
	<div>
		{showTitles && (
			<p>
				<span>{title}</span>
			</p>
		)}
		<p style={{ textTransform: 'capitalize' }}>{info}</p>
	</div>
);

export const AddressDisplay = ({
	address,
	showTitles,
	padding = true,
	border = true,
	isLabel = false
}: AddressDisplayProps): JSX.Element => (
	<AddressContainer padding={padding} border={border} isLabel={isLabel}>
		<AddressLineItem showTitles={showTitles!} title={'Recipient'} info={`${address.name}`} />
		<AddressLineItem
			showTitles={showTitles!}
			title={'Address'}
			info={`${address.streetName} ${address.streetNumber}, ${address.postNumber} ${address.postalTown}`}
		/>
		<AddressLineItem showTitles={showTitles!} title={'Country'} info={address.country} />
		{address.instructions && (
			<AddressLineItem
				showTitles={showTitles!}
				title={'Instructions'}
				info={address.instructions}
			/>
		)}
	</AddressContainer>
);
