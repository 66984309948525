import firebase from 'firebase';

export interface EventUser {
	filmId: string;
	id: string;
}

export interface IEvent {
	created: string;
	endDate?: string;
	title: string;
	eventCode: string;
	photosPerUser: number;
	public: boolean;
	active?: boolean;
	users: EventUser[];

	paymentSecret?: string;
	amount?: number;
	maxUsers?: number;
	email?: string;
}

export class LightsnapEvent implements IEvent {
	documentId?: string;
	created: string;
	endDate?: string;
	title: string;
	eventCode: string;
	photosPerUser: number;
	public: boolean;
	users: EventUser[];
	active: boolean;

	//new props
	paymentSecret?: string;
	amount?: number;
	maxUsers?: number;
	email?: string;

	constructor(
		created: string,
		title: string,
		eventCode: string,
		photosPerUser: number,
		isPublic: boolean,
		users: EventUser[],
		documentId?: string,
		endDate?: string,
		active?: boolean,
		paymentSecret?: string,
		amount?: number,
		maxUsers?: number,
		email?: string
	) {
		this.created = created;
		this.title = title;
		this.eventCode = eventCode;
		this.photosPerUser = photosPerUser;
		this.public = isPublic;
		this.users = users;
		this.documentId = documentId;
		this.endDate = endDate;
		this.active = !!active;
		this.paymentSecret = paymentSecret;
		this.amount = amount;
		this.maxUsers = maxUsers;
		this.email = email;
	}

	static fromSnapshot(data: firebase.firestore.DocumentData, documentId: string): LightsnapEvent {
		const event = { ...data } as IEvent;

		return new LightsnapEvent(
			event.created,
			event.title,
			event.eventCode,
			event.photosPerUser,
			event.public,
			event.users,
			documentId,
			event.endDate,
			event.active,
			event.paymentSecret,
			event.amount,
			event.maxUsers,
			event.email
		);
	}
}
