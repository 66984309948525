import React, { useContext, useEffect, useState } from 'react';
import Page from '../../components/Page';
import { FilmState } from '../../models/Film';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Delivery } from '../../models/Delivery';
import { useColumns } from '../../hooks/useColumns';
import { FeedTable } from './FeedTable';
import { LoadingIndicator } from '../../components/LoadingIndicator';
import Button from '../../components/Button';
import { AppContext } from '../../Darkroom';
import axios from 'axios';
import { LightsnapLabelModal } from '../../components/FeedBoard/LightsnapLabelModal';

enum PrintProvider {
	HANS,
	CRIMSON
}

export const FetchDeliveries = (): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> => {
	return firebase.firestore().collection('delivery');
};

const Feed = (): React.ReactNode => {
	const [isLabelOpen, setIsLabelOpen] = useState(false);

	const [loading, setLoading] = useState(true);
	const [columnState, dispatch] = useColumns({
		columns: [
			FilmState.printing,
			FilmState.printingProgress,
			FilmState.delivered,
			FilmState.sent
		]
	});

	const downloadImage = async () => {
		if (
			confirm(
				'Ertu viss um að þú viljir prenta pöntunarblað, þetta mun færa allar sendingar í sent!'
			)
		) {
			const printingFilms = columnState.columns.find(s => s.filmState === 'printingProgress');

			// Save it!
			const response = await axios.post(
				'https://api.mobiz.posturinn.is/api/v1/company-letter-registration',
				{
					countLetters: false,
					email: 'dev@lightsnap.app',
					identificationNumber: '4809201470',
					name: 'Lightsnap',
					nameOfCompany: 'Lightsnap Ehf.',
					numberOfTracableLetters: '',
					numberOfRegularLetters: printingFilms?.deliveries.filter(
						d => d.address.country === 'Iceland'
					).length,
					numberOfEuropeanLetters: printingFilms?.deliveries.filter(
						d => d.address!.country !== 'Iceland'
					).length,
					numberOfLettersOutsideEurope: '',
					reference: Math.floor(Math.random() * 10000)
				}
			);

			if (response.data && !response.data.error && response.data.orderNumber) {
				axios({
					url: `https://api.mobiz.posturinn.is/api/v1/company-letter-registration-pdf/${response.data.orderNumber}?pdfPassword=${response.data.pdfPassword}&email=dev@lightsnap.app`,
					method: 'GET',
					responseType: 'blob' // important
				})
					.then(r => {
						const url = window.URL.createObjectURL(new Blob([r.data]));
						const link = document.createElement('a');
						link.href = url;
						link.setAttribute('download', response.data.orderNumber + '-lightsnap.pdf');
						document.body.appendChild(link);
						link.click();
					})
					.catch((e: any) => {
						console.error('Posturinn API Error', e);
						alert(
							'Pósturinn.is - Virkar ekki að búa til fyrirtækjamiða. Filmur verða færðar yfir í "Sent"'
						);
					})
					.finally(() => {
						if (printingFilms) {
							printingFilms.deliveries.map(s => {
								s.setState(FilmState.sent);
							});
						}
					});
			}
		}
	};

	const appContext = useContext(AppContext);
	const showMore = () => {
		if (
			appContext?.email === 'geb.2991@gmail.com' ||
			appContext?.email === 'dev@lightsnap.app' ||
			appContext?.email === 'togga@lightsnap.app'
		) {
			return true;
		}
		return false;
	};
	const [printProvider, setPrintProvider] = useState(PrintProvider.HANS);

	useEffect(() => {
		FetchDeliveries().onSnapshot(querySnapshot => {
			if (querySnapshot.empty) {
				setLoading(false);
			} else {
				const deliveriesFromQuery = querySnapshot.docs.map(doc =>
					Delivery.fromSnapshot(doc.data(), doc.id)
				);

				console.log(deliveriesFromQuery);
				if (printProvider === PrintProvider.HANS) {
					//ONLY show iceland
					dispatch({
						type: 'setDeliveries',
						payload: deliveriesFromQuery
						/*.filter(
                            s => !['sweden'].includes(s.address.country.toLowerCase())
                        )*/
					});
				} else {
					//Only show non iceland
					dispatch({
						type: 'setDeliveries',
						payload: []
						/*.filter(s =>
                            ['sweden'].includes(s.address.country.toLowerCase())
                        )*/
					});
				}
				setLoading(false);
			}
		});
	}, [printProvider]);

	return (
		<Page
			pageContentProps={{
				style: {
					height: '100%',
					padding: '40px'
				}
			}}
		>
			{loading ? (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 50
					}}
				>
					<LoadingIndicator />
				</div>
			) : (
				<>
					<div style={{ marginBottom: '8px' }}>
						<Button onClick={() => setIsLabelOpen(true)}>Lightsnap Miði</Button>
					</div>
					{showMore() && (
						<>
							<Button
								onClick={() =>
									setPrintProvider(
										printProvider === PrintProvider.HANS
											? PrintProvider.CRIMSON
											: PrintProvider.HANS
									)
								}
							>
								{printProvider === PrintProvider.HANS
									? 'HANS VIEW'
									: 'CRIMSON VIEW'}
							</Button>
						</>
					)}

					<LightsnapLabelModal
						open={isLabelOpen}
						closeModal={() => setIsLabelOpen(false)}
					/>
					<FeedTable columns={columnState.columns} downloadImage={downloadImage} />
				</>
			)}
		</Page>
	);
};

export default Feed;
