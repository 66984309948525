import React from 'react';
import styled from 'styled-components';

interface LoadingSpinnerProps {
	size?: number;
	color?: string;
}

const initialSize = 80;
export const num2px = (value: number): string => `${value}px`;

const LoadingSpinner = styled.div<Required<LoadingSpinnerProps>>`
	display: inline-block;
	position: relative;
	width: ${(props): string => num2px(props.size)};
	height: ${(props): string => num2px(props.size)};
		
	div {
		box-sizing: border-box;
		display: block;
		position: absolute;
		width: ${(props): string => num2px((64 / 80) * props.size)};
		height: ${(props): string => num2px((64 / 80) * props.size)};
		margin: ${(props): string => num2px((8 / 80) * props.size)};
		border-radius: 50%;
		animation: ls-spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border-width: ${(props): string => num2px((8 / 80) * props.size)};
		border-style: solid;
		border-color: ${(props): string => props.color!} transparent transparent transparent;
		
		&:nth-child(1) {
			animation-delay: -0.45s;
		}
		&:nth-child(2) {
			animation-delay: -0.3s;
		}
		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
	
	@keyframes ls-spinner {
		0% {
			transform: rotate(0deg);
	}
		100% {
			transform: rotate(360deg);
	}
`;

export const Spinner = ({
	color = '#000',
	size = initialSize,
	...props
}: LoadingSpinnerProps): JSX.Element => (
	<LoadingSpinner size={size} color={color} {...props}>
		<div />
		<div />
		<div />
	</LoadingSpinner>
);
