import React from 'react';
import Page from '../../components/Page';

export const ProcessingQueue = () => {
	const token =
		'QQxuiyRjRpFqc7QIzvGCBorjzlqywFJ9gX2w7DRyWX8Xhawd4xKb3Xx9hzYcmKiAGSdvw49FVGkRSiuxa2j5m6MHCLMtZjqbzHeKkSY8Ca8eu75UcRTSJECBkdCgXAWQ0roe2LDv6UF4GtXz2LI3MedfTmC5beIZgbi4zFuIMaahdPOy8nVte7g0UFL42F1OZcaTzwTk';
	const imageServiceQueueDashboard = 'https://image-service.lightsnap.app/dashboard';

	return (
		<Page
			pageContentProps={{
				style: {
					padding: 0
				}
			}}
		>
			<div style={{ display: 'flex', width: '100%', height: '100%', flex: 1 }}>
				<iframe
					id={'queue-dashboard'}
					src={`${imageServiceQueueDashboard}/${token}`}
					style={{ width: '100%', height: '100%' }}
					frameBorder={0}
				/>
			</div>
		</Page>
	);
};
