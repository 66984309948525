import React, { useEffect, useState } from 'react';
import { PageHeader } from '../../components/PageHeader';
import Page from '../../components/Page';
import Button from '../../components/Button';
import { Table } from '../../components/Table';
import { Label } from '../../components/Label';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { Gift } from '../../models/Gift';
import faker from 'faker';
import swal from 'sweetalert';

const FetchGifts = async (): Promise<firebase.firestore.QuerySnapshot> => {
	return await firebase
		.firestore()
		.collection('gift')
		.get();
};

export const Gifts = (): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const [gifts, setGifts] = useState<Gift[]>([]);

	useEffect(() => {
		FetchGifts()
			.then(querySnapshot => {
				if (querySnapshot.empty) {
					// eslint-disable-next-line no-console
					console.error('Empty gifts...');
					setLoading(false);
					return;
				} else {
					const giftsFromQuery = querySnapshot.docs.map(doc =>
						Gift.fromSnapshot(doc.data(), doc.id)
					);
					setGifts(giftsFromQuery);
					setLoading(false);
				}
			})
			.catch(e => {
				// eslint-disable-next-line no-console
				console.error(e);
			});
	}, []);

	const newGift = (prefix: string) => {
		const randomWord = faker.random.word().replace(/ /g, '');

		firebase
			.firestore()
			.collection('gift')
			.doc(prefix + '-' + randomWord)
			.set({
				created: new Date().toISOString(),
				fromUserId: 'jaSHY5apo7T8lgIbO8OqkMgg50H3'
			})
			.then(() => swal(`Gift created: ${prefix + '-' + randomWord}`));
	};
	return (
		<Page>
			<PageHeader title={'Gifts'} description={'Create and manage gift codes'}>
				<Button
					onClick={(): void => {
						swal('What prefix do you want for the gift:', {
							content: {
								element: 'input',
								attributes: {
									placeholder: 'giftPrefix',
									value: ''
								}
							}
						}).then(value => {
							if (value?.length > 0) {
								newGift(value);
							}
						});
					}}
				>
					Make a gift
				</Button>
			</PageHeader>
			{loading ? (
				<p>Loading ...</p>
			) : (
				<Table
					columns={[
						{ text: 'Created', align: 'left' },
						{ text: 'Gift code', align: 'left' },
						{ text: 'Redeemed', align: 'left' },
						{ text: 'Send', align: 'right' }
					]}
				>
					{gifts.map(gift => (
						<tr key={gift.documentId}>
							<td>{gift.created}</td>
							<td>
								<a
									href={`https://lightsnap.app/redeem/${gift.documentId}`}
									target={'_blank'}
								>
									{gift.documentId}
								</a>
							</td>
							<td>
								<Label appearance={gift.redeemed ? 'success' : 'normal'}>
									{gift.redeemed ? 'Yes' : 'No'}
								</Label>
							</td>
							<td align={'right'}>
								<Button onClick={() => alert('Not implemented')}>Send SMS</Button>
							</td>
						</tr>
					))}
				</Table>
			)}
		</Page>
	);
};
