import React from 'react';
import styled from 'styled-components';

const PageHeaderContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	flex-wrap: nowrap;
	padding: ${(props): string => props.theme.padding(4)} 0;
`;

const PageHeaderTitle = styled.div`
	h1 {
		margin-top: 0;
		font-size: 24px;
	}

	p {
		color: #666;
		margin-bottom: 0;
	}
`;

interface PageHeaderProps {
	title: string;
	description?: string;
	children?: React.ReactNode;
}

export const PageHeader = ({ title, description, children }: PageHeaderProps): JSX.Element => (
	<PageHeaderContainer>
		<PageHeaderTitle>
			<h1>{title}</h1>
			{description && <p>{description}</p>}
		</PageHeaderTitle>
		{children && <div>{children}</div>}
	</PageHeaderContainer>
);
