import { Dispatch, useReducer } from 'react';
import { FilmState } from '../models/Film';
import { IDelivery } from '../models/Delivery';
import { FeedboardColumn } from '../models/FeedboardColumn';

interface ColumnProps {
	columns: FilmState[];
}

interface ColumnState {
	deliveries: IDelivery[];
	columns: FeedboardColumn[];
}

export type ColumnAction =
	| { type: 'setDeliveries'; payload: IDelivery[] }
	| {
			type: 'moveDeliveryColumn';
			deliveryInstanceId: string;
			fromState: FilmState;
			toState: FilmState;
			callback: (delivery: IDelivery) => void;
	  };

// Used for finding a column in by it's state.
const getColumn = (state: ColumnState, filmState: FilmState): FeedboardColumn | undefined => {
	return state.columns.find(c => filmState.toString() === c.toString());
};

const reducer = (state: ColumnState, action: ColumnAction): ColumnState => {
	switch (action.type) {
		case 'setDeliveries': {
			const newState = { ...state };
			newState.deliveries = action.payload;

			// Map deliveries to columns
			newState.columns = [...state.columns].map(col => {
				col.setDeliveries(newState.deliveries.filter(d => d.state === col.filmState));
				return col;
			});

			return newState;
		}
		case 'moveDeliveryColumn': {
			const newColumns = [...state.columns];
			const fromColumn = getColumn(state, action.fromState);
			const toColumn = getColumn(state, action.toState);

			if (fromColumn && toColumn) {
				const delivery = fromColumn.deliveries.find(
					d => d.deliveryInstanceId === action.deliveryInstanceId
				);

				if (delivery) {
					fromColumn.removeDelivery(delivery);
					toColumn.addDelivery(delivery);

					// Replace the columns with the modified ones
					newColumns[
						newColumns.findIndex(col => col.toString() === fromColumn.toString())
					] = fromColumn;
					newColumns[
						newColumns.findIndex(col => col.toString() === toColumn.toString())
					] = toColumn;

					try {
						action.callback(delivery);
					} catch (e) {
						console.log(e);
					}

					return {
						...state,
						columns: newColumns
					};
				}
			}

			console.error('moveDeliveryColumn failed, no columns found.');
			return state;
		}
		default:
			throw new Error('Invalid reducer action for useColumns');
	}
};

export const useColumns = ({ columns }: ColumnProps): [ColumnState, Dispatch<ColumnAction>] =>
	useReducer(reducer, {
		deliveries: [],
		columns: columns.map(filmState => new FeedboardColumn(filmState))
	});
