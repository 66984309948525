import { FilmState, IFilm } from './Film';
import { IAddress } from './Address';
import firebase from 'firebase/app';
import { ProcessStatus } from './ProcessStatus';

type PureDelivery<T> = Omit<T, 'toJson' | 'setState'>;

export interface IDelivery extends Pick<IFilm, 'title' | 'state'> {
	userId: string;
	filmId: string;
	addressId: string;
	deliveryInstanceId: string;
	address: IAddress;
	download?: string;
	processing?: ProcessStatus;
	processErrorReason?: string | object;
	documentId?: string;

	toJson(): PureDelivery<IDelivery>;
	setState(state: FilmState): Promise<void>;
}

export class Delivery implements IDelivery {
	userId: string;
	filmId: string;
	title: string;
	state: FilmState;
	addressId: string;
	deliveryInstanceId: string;
	address: IAddress;
	download?: string;
	processing?: ProcessStatus;
	processErrorReason?: string | object;
	documentId?: string;

	constructor(
		userId: string,
		filmId: string,
		title: string,
		state: FilmState,
		addressId: string,
		deliveryInstanceId: string,
		address: IAddress,
		download?: string,
		processing?: ProcessStatus,
		processErrorReason?: string | object,
		documentId?: string
	) {
		this.userId = userId;
		this.filmId = filmId;
		this.title = title;
		this.state = state;
		this.addressId = addressId;
		this.deliveryInstanceId = deliveryInstanceId;
		this.address = address;
		this.download = download;
		this.processing = processing;
		this.processErrorReason = processErrorReason;
		this.documentId = documentId;
	}

	async setState(state: FilmState): Promise<void> {
		this.state = state;

		if (this.documentId) {
			// Update firebase
			await firebase
				.firestore()
				.collection('delivery')
				.doc(this.documentId)
				.set({ state }, { merge: true });
		}
	}

	toJson(): PureDelivery<IDelivery> {
		return {
			userId: this.userId,
			filmId: this.filmId,
			title: this.title,
			state: this.state,
			addressId: this.addressId,
			deliveryInstanceId: this.deliveryInstanceId,
			address: this.address,
			download: this.download,
			processing: this.processing,
			processErrorReason: this.processErrorReason
		};
	}

	static fromSnapshot(data: firebase.firestore.DocumentData, documentId?: string): Delivery {
		const d = { ...data } as Delivery;
		return new Delivery(
			d.userId,
			d.filmId,
			d.title,
			d.state,
			d.addressId,
			d.deliveryInstanceId,
			d.address,
			d.download,
			d.processing,
			d.processErrorReason,
			documentId
		);
	}
}
