// Should be in sync with model/FilmState.dart
import { FormatDate } from '../utils/FormatDate';

export enum FilmState {
	active = 'active',
	ready = 'ready',
	printing = 'printing',
	printingProgress = 'printingProgress',
	sent = 'sent',
	delivered = 'delivered'
}

export interface IFilm {
	id?: string;
	inUse: boolean;
	title: string;
	capacity: 24; // Add more capacity categories later
	filterId: 1; // Add more filter Ids later
	state: FilmState;
	photoCount: number;
}

export class Film implements IFilm {
	id?: string;
	title: string;
	inUse: boolean;
	state: FilmState;
	capacity: 24 = 24;
	filterId: 1 = 1;
	photoCount: number;

	constructor(
		title: string,
		inUse: boolean,
		state: FilmState = FilmState.active,
		id = undefined
	) {
		this.title = title.length > 0 ? title : FormatDate(new Date());
		this.inUse = inUse;
		this.state = state;
		this.photoCount = 0; // Always initialize with 0 rite?
		this.id = id; // New films have no ids
	}

	// Used in cloud functions
	toJson(): Omit<IFilm, 'toJson'> {
		return {
			title: this.title,
			inUse: this.inUse,
			capacity: this.capacity,
			filterId: this.filterId,
			photoCount: this.photoCount,
			state: this.state
		};
	}
}
