import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
	width: 100%;
	thead > tr > th {
		border-bottom: 1px solid #e5e5e5;
	}

	tr > td,
	tr > th {
		padding: ${(props): string => props.theme.padding(1)};
	}
`;

interface Column {
	text: string;
	align?: 'left' | 'center' | 'right';
	width?: number | string;
}

interface TableProps {
	columns: Column[];
	children: React.ReactNode;
}

export const Table = ({ children, columns }: TableProps): JSX.Element => {
	return (
		<StyledTable>
			<thead>
				<tr>
					{columns.map(({ text, ...props }, headerColIndex) => (
						<th key={headerColIndex} {...props}>
							{text}
						</th>
					))}
				</tr>
			</thead>
			<tbody>{children}</tbody>
		</StyledTable>
	);
};
