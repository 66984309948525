import React from 'react';
import { FeedboardColumn } from '../../models/FeedboardColumn';
import styled, { css } from 'styled-components';

export const FeedTableTabs = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	margin-bottom: 24px;
`;

const Tab = styled.div<{ active: boolean }>`
	cursor: pointer;
	white-space: nowrap;
	transition: all 0.2s linear;
	border-radius: 24px;

	&:hover {
		background: #eee;
	}

	> div {
		display: flex;
		align-items: center;
		padding: 12px 24px;
	}

	&:not(:last-child) {
		margin-right: 16px;
	}

	${props =>
		props.active &&
		css`
			background: #d9d7e7;
			color: #888ba1;
			border-bottom-color: #fff;

			span {
				font-weight: bold;
			}
		`}
`;

const TabCounter = styled.div<{ active: boolean }>`
	margin-left: 16px;
	border-radius: 12px;
	padding: 4px 8px;
	font-size: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #888ba1;
	background: #d9d7e7;

	${props =>
		props.active &&
		css`
			background: #fff;
			color: #000;
		`}
`;

interface FeedTableTabProps {
	column: FeedboardColumn;
	onClick: () => void;
	downloadImage: () => void;
	active: boolean;
}

export const FeedTableTab = ({ active, column, onClick }: FeedTableTabProps): JSX.Element => (
	<Tab onClick={onClick} active={active}>
		<div>
			<span>{column.getTitle()}</span>
			<TabCounter active={active}>
				<span>{column.deliveries.length}</span>
			</TabCounter>
		</div>
	</Tab>
);
