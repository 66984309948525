import React, { useReducer } from 'react';
import { shuffle } from '../../../utils/ArrayShuffle';

export type PhotoObject = {
	userId: string;
	photoUrl: string;
	filmId: string;
	beenDisplayed: boolean;
};

interface ImageStreamState {
	bannedPhotos: string[];
	photos: Map<string, PhotoObject>;
	currentImage?: PhotoObject;
}

type ImageStreamAction =
	| { type: 'addImage'; payload: PhotoObject }
	| { type: 'removeImage'; payload: PhotoObject }
	| { type: 'showNewImage' };

const reducer = (state: ImageStreamState, action: ImageStreamAction): ImageStreamState => {
	switch (action.type) {
		case 'addImage': {
			const addPhoto = (photo: PhotoObject): Map<string, PhotoObject> => {
				return state.photos.set(photo.photoUrl, photo);
			};

			const photoObject = action.payload;
			console.log('Adding image', photoObject);

			if (
				!state.photos.has(photoObject.photoUrl) &&
				!state.bannedPhotos.includes(photoObject.photoUrl)
			) {
				console.log('Did not exist');
				const newPhotos = addPhoto(photoObject);

				return {
					...state,
					currentImage: state.currentImage ? state.currentImage : photoObject,
					photos: newPhotos
				};
			}

			return state;
		}
		case 'showNewImage': {
			const currentState = { ...state };
			const photos = Array.from(currentState.photos.values());
			const shuffled = shuffle<PhotoObject>(photos);

			if (shuffled.find(x => !x.beenDisplayed)) {
				const newImage = shuffled.find(p => !p.beenDisplayed)!;
				currentState.currentImage = newImage;
				currentState.photos.get(newImage.photoUrl)!.beenDisplayed = true;
				return currentState;
			} else {
				// Reset all images and find a new one
				let i = 0;
				const newMap = new Map<string, PhotoObject>();
				let currentImage: PhotoObject | undefined;

				for (const photo of photos) {
					photo.beenDisplayed = i === 0;
					if (i === 0) {
						currentImage = photo;
					}
					newMap.set(photo.photoUrl, photo);
					i++;
				}

				return {
					...currentState,
					currentImage,
					photos: newMap
				};
			}
		}
		case 'removeImage': {
			if (state.photos.has(action.payload.photoUrl)) {
				const newMap = state.photos;
				newMap.delete(action.payload.photoUrl);

				const bannedPhotos = state.bannedPhotos;
				bannedPhotos.push(action.payload.photoUrl);

				return {
					...state,
					bannedPhotos,
					photos: newMap
				};
			}

			return state;
		}
		default:
			throw new Error('Invalid action');
	}
};

export const useImageStream = (
	initialState: ImageStreamState
): [ImageStreamState, React.Dispatch<ImageStreamAction>] => {
	return useReducer(reducer, initialState);
};

export const ImageStreamContext = React.createContext<{
	state: ImageStreamState;
	dispatch: React.Dispatch<ImageStreamAction>;
}>({
	state: {
		bannedPhotos: [],
		photos: new Map<string, PhotoObject>(),
		currentImage: undefined
	},
	dispatch: () => null
});
