import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import Button from '../../components/Button';
import { PageHeader } from '../../components/PageHeader';
import firebase from 'firebase';
import { Label } from '../../components/Label';
import { Table } from '../../components/Table';
import { IEvent, LightsnapEvent } from '../../models/Event';
import { Modal } from '../../components/Modal';
import { EventForm } from './components/EventForm';
import { Link } from 'react-router-dom';
import { TextField, Switch, FormControlLabel } from '@material-ui/core';

const FetchEvents = async (): Promise<firebase.firestore.QuerySnapshot> => {
	return await firebase
		.firestore()
		.collection('event')
		.get();
};

export const Events = (): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [events, setEvents] = useState<LightsnapEvent[]>();
	const [creatingEvent, setCreatingEvent] = useState(false);

	const [search, setSearch] = useState('');
	const [isBuy, setIsBuy] = useState(false);
	useEffect(() => {
		// createEvent(null);
		FetchEvents()
			.then(querySnapshot => {
				if (querySnapshot.empty) {
					// eslint-disable-next-line no-console
					console.error('Empty events...');
					setLoading(false);
					return;
				} else {
					const eventsFromQuery = querySnapshot.docs.map(doc => {
						return LightsnapEvent.fromSnapshot(doc.data(), doc.id);
					});
					setEvents(eventsFromQuery);
					setLoading(false);
				}
			})
			.catch(e => {
				// eslint-disable-next-line no-console
				console.error(e);
			});
	}, []);

	const createEvent = async (data: IEvent) => {
		//const collectionRef = firebase.firestore().collection('event');

		/*		//TODO skoda
        const fwfw = await collectionRef
            .where('endDate', '>', '') // not null ish
            .get();

        const eve = fwfw.docs.map(s => s.data());

        console.log(eve);
        const today = new Date();

        for (const ss of eve) {
            if (new Date(ss.endDate).getTime() > today.getTime()) {
                console.log('jeb', ss.title);
            } else {
                console.log('nob', ss.title);
            }
            console.log(ss.users[0], 'rabbes');

            const userRef = await firebase
                .firestore()
                .collection('user')
                .doc(ss.users[0].id);

            console.log(userRef.id, 'result');

            const result2 = await userRef
                .collection('films')
                .doc(ss.users[0].filmId)
                .get();

            console.log(result2.data(), 'result2');
        }*/

		try {
			const response = await firebase
				.firestore()
				.collection('event')
				.add(data);

			// eslint-disable-next-line no-console
			console.log('Success', response.id);
		} catch (e) {
			alert('Error came up when creating an event, check console for details');
			// eslint-disable-next-line no-console
			console.error(e);
		}
	};
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December'
	];
	const formatDate = (date?: string) => {
		if (!date || date === '') {
			return '';
		}
		const formated = new Date(date);
		return (
			formated.getDate() +
			' ' +
			monthNames[formated.getMonth()].substring(0, 3) +
			' ' +
			formated.getHours() +
			':' +
			formated.getMinutes() +
			' ' +
			formated.getFullYear()
		);
	};

	const filteredOrderedEvents = events
		?.filter(f => {
			if (search.length > 0) {
				return f.title.includes(search) || f.eventCode.includes(search) || f.email?.includes(search);
			}
			return true;
		})
		.filter(r => {
			if (isBuy) {
				return r.paymentSecret !== undefined;
			}
			return true;
		})
		// @ts-ignore
		.sort((a, b) => new Date(b.created) - new Date(a.created));
	return (
		<>
			<Modal
				open={creatingEvent}
				title={'Create event'}
				onClose={() => setCreatingEvent(false)}
			>
				<EventForm
					onCancel={() => setCreatingEvent(false)}
					submitEvent={data => createEvent(data)}
				/>
			</Modal>
			<Page>
				<PageHeader title={'Events'} description={'Create and manage Lightsnap events'}>
					<Button onClick={(): void => setCreatingEvent(true)}>Create an Event</Button>
				</PageHeader>

				<div>
					<TextField
						style={{ marginRight: '8px' }}
						placeholder={'Search'}
						value={search}
						onChange={e => {
							setSearch(e.target.value);
						}}
					/>
					<FormControlLabel
						control={<Switch value={isBuy} onChange={() => setIsBuy(!isBuy)} />}
						label="Only buy events"
					/>
				</div>
				{loading ? (
					<p>Loading...</p>
				) : (
					<Table
						columns={[
							{ text: 'Created', align: 'left' },
							{ text: 'Expires', align: 'left' },
							{ text: 'Title', align: 'left' },
							{ text: 'Email', align: 'left' },
							{ text: 'Photos per', align: 'center' },
							{ text: 'Max users', align: 'center' },
							{ text: 'Users', align: 'center' },
							{ text: 'Public', align: 'center' },
							{ text: 'Active', align: 'center' },
							{ text: 'Buy', align: 'center' }
						]}
					>
						{filteredOrderedEvents?.map(event => (
							<tr key={event.documentId}>
								<td>{formatDate(event.created)}</td>
								<td>{formatDate(event.endDate)}</td>
								<td>
									<div style={{ display: 'flex', flexDirection: 'column' }}>
										<span style={{ textDecoration: 'underline ' }}>
											<Link to={'/event/tv/' + event.documentId}>
												{event.title}
											</Link>
										</span>
										<span style={{ fontSize: '12px' }}>#{event.eventCode}</span>
									</div>
								</td>
								<td>
									{event.email}
								</td>
								<td align={'center'}>{event.photosPerUser}</td>
								<td align={'center'}>{event.maxUsers}</td>

								<td align={'center'}>{event.users?.length || 0}</td>
								<td align={'center'}>
									<Label appearance={event.public ? 'success' : 'normal'}>
										{event.public ? 'Yes' : 'No'}
									</Label>
								</td>
								<td align={'center'}>
									<Label appearance={event.active ? 'success' : 'normal'}>
										{event.active ? 'Yes' : 'No'}
									</Label>
								</td>
								<td align={'center'}>
									<Label
										appearance={
											event.paymentSecret === undefined ? 'normal' : 'success'
										}
									>
										{event.paymentSecret === undefined ? 'No' : 'Yes'}
									</Label>
								</td>
							</tr>
						))}
					</Table>
				)}
			</Page>
		</>
	);
};
