import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { InputWithLabel } from '../InputWithLabel';
import Button from '../Button';

interface EventFormProps {
	onCancel(): void;
	submitEvent(data: { smsContent: string }): Promise<void>;
}

const EventFormStyled = styled.form`
	> div:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const FormButtons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const FilmSendSmsForm = ({ onCancel, submitEvent }: EventFormProps): JSX.Element => {
	const { register, handleSubmit, errors, setValue } = useForm<{ smsContent: string }>();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (data: { smsContent: string }) => {
		setLoading(true);
		await submitEvent(data);
		setLoading(false);
		onCancel();
	};
	const setDefaultIsValue = () => {
		setValue(
			'smsContent',
			'Halló, Lightsnap hér, svo virðist sem að Lightsnap filman þín hafi ekki ratað heim til þín, gætiru haft samband við okkur á Facebook eða Instagram þannig við getum sent aftur :)'
		);
	};
	const setDefaultEnValue = () => {
		setValue(
			'smsContent',
			'Hello, Lightsnap here, it seems that your Lightsnap film got returned from the postal office, could you contact us via Facebook or Instagram so we can sort things out :)'
		);
	};
	return (
		<div>
			<EventFormStyled onSubmit={handleSubmit(onSubmit)}>
				<div>
					<InputWithLabel
						error={errors.smsContent}
						name={'smsContent'}
						label={'Sms content'}
						register={register}
						required={true}
					/>
				</div>
				<div>
					<a style={{ paddingRight: '8px' }} onClick={setDefaultIsValue}>
						Ísl
					</a>
					<a onClick={setDefaultEnValue}>En</a>
				</div>

				<FormButtons>
					<Button type={'submit'} loading={loading}>
						Send sms
					</Button>
					<Button
						appearance={'inverted-border'}
						type={'button'}
						onClick={() => onCancel()}
					>
						Cancel
					</Button>
				</FormButtons>
			</EventFormStyled>
		</div>
	);
};
