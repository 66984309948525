import React, { useCallback, useEffect, useState } from 'react';
import firebase from 'firebase';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';
import { LightsnapEvent } from '../../models/Event';
import { Spinner } from '../../components/Loading/Spinner';
import { EventChannel } from './components/EventChannel';

const FullscreenContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const ErrorMessage = styled.p`
	color: red;
	font-size: 22px;
	font-weight: bold;
	text-align: center;
`;

export const EventTV = ({ match }: RouteComponentProps<{ eventId: string }>): JSX.Element => {
	const { params } = match;
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | null>();
	const [event, setEvent] = useState<LightsnapEvent>();

	const updateEventData = (eventSnapshot: firebase.firestore.DocumentSnapshot) => {
		try {
			if (eventSnapshot.exists) {
				const eventData = LightsnapEvent.fromSnapshot(
					eventSnapshot.data()!,
					eventSnapshot.id
				);

				setEvent(eventData);
			} else {
				setError('Invalid Event Snapshot');
			}
		} catch (e) {
			setError('Could not parse event details');
		}
		setLoading(false);
	};

	useEffect(() => {
		const fetchEvent = async (eventId: string) => {
			setLoading(true);

			const docRef = firebase
				.firestore()
				.collection('event')
				.doc(eventId);

			const collection = await docRef.get();

			if (collection.exists) {
				docRef.onSnapshot(snapshot => updateEventData(snapshot));
			} else {
				setLoading(false);
				setError(`Could not find event with id: ${params.eventId}`);
			}
		};

		fetchEvent(params.eventId);
	}, [params.eventId]);

	if (loading) {
		return (
			<FullscreenContainer>
				<Spinner color={'#000'} size={64} />
			</FullscreenContainer>
		);
	}

	if (error || !event) {
		return (
			<FullscreenContainer>
				<ErrorMessage>
					{error || 'Could not load event data, contact Lightsnap support.'}
				</ErrorMessage>
			</FullscreenContainer>
		);
	}

	return (
		<FullscreenContainer>
			<EventChannel event={event} />
		</FullscreenContainer>
	);
};
