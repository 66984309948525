import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import firebase from 'firebase';
import styled from 'styled-components';
import { GlobalStat } from '../../models/GlobalStat';
import LineChart from './components/LineChart';
import { Tab, Tabs } from '@material-ui/core';
import LBarChart from './components/BarChart';

const FetchGlobalStats = async (): Promise<firebase.firestore.QuerySnapshot> => {
	return await firebase
		.firestore()
		.collection('global_stats')
		.get();
};

const Boxes = styled.div`
	display: flex;
	flex-wrap: wrap;
	padding: 8px;
`;

const getData = (array: GlobalStat[], key: keyof GlobalStat, filter0Values?: boolean) => {
	const returnArray = array.map(s => {
		return {
			name: s.timestamp.toDate().toDateString(),
			value: s[key]
		};
	});

	if (filter0Values) {
		return returnArray.filter(s => s.value !== 0 && !!s.value);
	}
	return returnArray;
};
const getGrowth = (array: GlobalStat[], key1: keyof GlobalStat) => {
	const bla = [];
	for (let i = 0; i < array.length; i++) {
		const s = array[i];
		if (i < array.length - 2) {
			const value1 = array[i + 1][key1] - s[key1];
			if (value1 >= 0) {
				bla.push({
					name: s.timestamp.toDate().toDateString(),
					value1
				});
			}
		} else {
			break;
		}
	}
	return bla;
};

// @ts-ignore
const toPercentage = (number?: number) => {
	if (!number) {
		return 'HMMMM??';
	}
	return `${parseFloat(String(number * 100)).toFixed(2)}%`;
};
export const Metrics = (): JSX.Element => {
	const [loading, setLoading] = useState(false);
	const [globalStats, setGlobalStat] = useState<GlobalStat[]>();

	const [currentTab, changeTab] = useState(6);

	// @ts-ignore
	const handleChange = (event, newValue) => {
		changeTab(newValue);
	};
	useEffect(() => {
		FetchGlobalStats()
			.then(querySnapshot => {
				if (querySnapshot.empty) {
					// eslint-disable-next-line no-console
					console.error('Empty events...');
					setLoading(false);
					return;
				} else {
					const novaFilmsFromQuery: GlobalStat[] = querySnapshot.docs.map(doc => {
						return doc.data() as GlobalStat;
					});

					const ordered = novaFilmsFromQuery.sort((a, b) => {
						return a.timestamp.toDate() - b.timestamp.toDate();
					});
					setGlobalStat(ordered);
					setLoading(false);
				}
			})
			.catch(e => {
				// eslint-disable-next-line no-console
				console.error(e);
			});
	}, []);

	let latest;
	let totalUsersWithFilm = 1;
	if (globalStats) {
		latest = globalStats[globalStats.length - 1];

		// some numbers that are reused often

		totalUsersWithFilm =
			latest?.totalActiveUsers +
			latest?.totalSemiActiveUsers +
			latest?.totalMonthlyActiveUsers +
			latest?.totalInactiveUsersWithFilm;
	}
	// @ts-ignore
	return (
		<>
			<Page>
				{loading ? (
					<div>loading</div>
				) : (
					<div>
						{globalStats && globalStats?.length > 0 && (
							<div>
								<Tabs
									value={currentTab}
									onChange={handleChange}
									aria-label="simple tabs example"
								>
									<Tab label="Users growth" />
									<Tab label="Users retention" />
									<Tab label="Film growth" />
									<Tab label="Film retention " />
									<Tab label="Film retention w/delivery" />
									<Tab label="Nova campaign" />
									<Tab label="Sweden campaign" />
									<Tab label="Summary" />
								</Tabs>

								{currentTab === 0 && (
									<Boxes>
										<LineChart
											title={'Total users'}
											data={getData(globalStats, 'totalUsers')}
										/>
										<LBarChart
											title={'New users per day'}
											data={getGrowth(globalStats, 'totalUsers')}
										/>
										<LineChart
											title={'Total icelandic users'}
											data={getData(globalStats, 'totalIcelandicUsers')}
										/>
										<LineChart
											title={'Total swedish users'}
											data={getData(globalStats, 'totalSwedishUsers')}
										/>
										<LBarChart
											title={'New icelandic per day'}
											data={getGrowth(globalStats, 'totalIcelandicUsers')}
										/>
									</Boxes>
								)}
								{currentTab === 1 && (
									<Boxes>
										<LineChart
											title={'Total Active Users'}
											subTitle={toPercentage(
												latest?.totalActiveUsers / totalUsersWithFilm
											)}
											data={getData(globalStats, 'totalActiveUsers', true)}
										/>
										<LineChart
											title={'Total Semi-Active Users'}
											subTitle={toPercentage(
												latest?.totalSemiActiveUsers / totalUsersWithFilm
											)}
											data={getData(
												globalStats,
												'totalSemiActiveUsers',
												true
											)}
										/>
										<LineChart
											title={'Total Monthly-Active Users'}
											subTitle={toPercentage(
												latest?.totalMonthlyActiveUsers / totalUsersWithFilm
											)}
											data={getData(
												globalStats,
												'totalMonthlyActiveUsers',
												true
											)}
										/>
										<LineChart
											title={'Total InActive with film'}
											subTitle={toPercentage(
												latest?.totalInactiveUsersWithFilm /
													totalUsersWithFilm
											)}
											data={getData(
												globalStats,
												'totalInactiveUsersWithFilm',
												true
											)}
										/>
									</Boxes>
								)}

								{currentTab === 2 && (
									<Boxes>
										<LineChart
											title={'Films bought'}
											data={getData(globalStats, 'totalBoughtFilms')}
										/>
										<LBarChart
											title={'Films bought per day'}
											data={getGrowth(globalStats, 'totalBoughtFilms')}
										/>
										<LineChart
											title={'Films in system'}
											data={getData(globalStats, 'totalFilmsInSystem')}
										/>
										<LineChart
											title={'Gifts redeemed'}
											data={getData(globalStats, 'totalGiftsRedeemed')}
										/>
									</Boxes>
								)}
								{currentTab === 3 && (
									<Boxes>
										<LineChart
											title={'No film users'}
											data={getData(
												globalStats,
												'totalUsersWithNoFilmsBought',
												true
											)}
										/>
										<LineChart
											title={'1 film users'}
											data={getData(
												globalStats,
												'totalUsersWithOneFilmsBought',
												true
											)}
										/>
										<LineChart
											title={'2 film users'}
											data={getData(
												globalStats,
												'totalUsersWithTwoFilmsBought',
												true
											)}
										/>
										<LineChart
											title={'3 film users'}
											data={getData(
												globalStats,
												'totalUsersWithThreeFilmsBought',
												true
											)}
										/>
										<LineChart
											title={'4+ film users'}
											data={getData(
												globalStats,
												'totalUsersWithFourPlusFilmsBought',
												true
											)}
										/>
									</Boxes>
								)}
								{currentTab === 4 && (
									<Boxes>
										<LineChart
											title={'1 film users w/delivery'}
											data={getData(
												globalStats,
												'totalUsersWithOneFilmsBoughtWithDelivery',
												true
											)}
										/>
										<LineChart
											title={'2 film users w/delivery'}
											data={getData(
												globalStats,
												'totalUsersWithTwoFilmsBoughtWithDelivery',
												true
											)}
										/>
										<LineChart
											title={'3 film users w/delivery'}
											data={getData(
												globalStats,
												'totalUsersWithThreeFilmsBoughtWithDelivery',
												true
											)}
										/>
										<LineChart
											title={'4+ film users w/delivery'}
											data={getData(
												globalStats,
												'totalUsersWithFourPlusFilmsBoughtWithDelivery',
												true
											)}
										/>
									</Boxes>
								)}
								{currentTab === 5 && (
									<Boxes>
										<LineChart
											title={'Nova tried'}
											data={getData(globalStats, 'totalNovaTried')}
										/>
										<LineChart
											title={'Nova successful redeem'}
											data={getData(globalStats, 'totalNovaFilmsRedeemed')}
										/>
										<LineChart
											title={'Nova redeem to delivery'}
											data={getData(globalStats, 'totalNovaRedeemToDelivery')}
										/>
										<LineChart
											title={'Nova redeem to film bought'}
											data={getData(
												globalStats,
												'totalNovaRedeemedToFilmBought'
											)}
										/>
										<LineChart
											title={'Nova redeem(film bought and delivery)'}
											data={getData(
												globalStats,
												'totalNovaRedeemedToFilmBoughtWithDelivery'
											)}
										/>
									</Boxes>
								)}
								{currentTab === 6 && (
									<Boxes>
										<LineChart
											title={'Sweden tried'}
											data={getData(globalStats, 'totalSwedenTried')}
										/>
										<LineChart
											title={'Sweden successful redeem'}
											data={getData(globalStats, 'totalSwedenFilmsRedeemed')}
										/>
										<LineChart
											title={'Sweden redeem to delivery'}
											data={getData(
												globalStats,
												'totalSwedenRedeemToDelivery'
											)}
										/>
										<LineChart
											title={'Sweden redeem to film bought'}
											data={getData(
												globalStats,
												'totalSwedenRedeemedToFilmBought'
											)}
										/>
										<LineChart
											title={'Sweden redeem(film bought and delivery)'}
											data={getData(
												globalStats,
												'totalSwedenRedeemedToFilmBoughtWithDelivery'
											)}
										/>
									</Boxes>
								)}
								{currentTab === 7 && (
									<Boxes>
										<LineChart
											title={'Total Icelandic users'}
											data={getData(globalStats, 'totalIcelandicUsers')}
											subTitle={latest?.totalIcelandicUsers}
										/>
										<LineChart
											title={'Total Swedish users'}
											data={getData(globalStats, 'totalSwedishUsers')}
											subTitle={latest?.totalSwedishUsers}
										/>
										<LineChart
											title={'Total Cash'}
											data={getData(globalStats, 'totalMoney')}
											subTitle={latest?.totalMoney}
										/>
										<LineChart
											title={'Total pictures'}
											data={getData(globalStats, 'totalPicturesTaken')}
											subTitle={latest?.totalPicturesTaken}
										/>
										<LineChart
											title={'Total buy film'}
											data={getData(globalStats, 'totalBoughtFilms')}
											subTitle={latest?.totalBoughtFilms}
										/>
										<div>
											<div>
												Total active user of people with film
												<b>
													{toPercentage(
														(latest?.totalSemiActiveUsers +
															latest?.totalActiveUsers) /
															totalUsersWithFilm
													)}
												</b>
											</div>
											<div>
												Total active iceland
												<b>
													{toPercentage(
														(latest?.totalSemiActiveUsers +
															latest?.totalActiveUsers) /
															latest?.totalIcelandicUsers
													)}
												</b>
											</div>
											<br />
											<br />
											<div>
												Total delivery to buy film retention:
												<b>
													{toPercentage(
														(latest?.totalUsersWithTwoFilmsBoughtWithDelivery +
															latest?.totalUsersWithThreeFilmsBoughtWithDelivery +
															latest?.totalUsersWithFourPlusFilmsBoughtWithDelivery) /
															latest?.totalUserWithDelivery
													)}
												</b>
											</div>
											<div>
												Total delivery first buy to second retention:
												<b>
													{toPercentage(
														(latest?.totalUsersWithTwoFilmsBoughtWithDelivery +
															latest?.totalUsersWithThreeFilmsBoughtWithDelivery +
															latest?.totalUsersWithFourPlusFilmsBoughtWithDelivery) /
															(latest?.totalUsersWithOneFilmsBoughtWithDelivery +
																latest?.totalUsersWithTwoFilmsBoughtWithDelivery +
																latest?.totalUsersWithThreeFilmsBoughtWithDelivery +
																latest?.totalUsersWithFourPlusFilmsBoughtWithDelivery)
													)}
												</b>
											</div>
											<div>
												Nova percentage delivery to film buy: *
												<b>
													{toPercentage(
														latest?.totalNovaRedeemedToFilmBoughtWithDelivery /
															latest?.totalNovaRedeemToDelivery
													)}
												</b>
											</div>
											<div>
												Sweden percentage delivery to film buy: *
												<b>
													{toPercentage(
														latest?.totalSwedenRedeemedToFilmBoughtWithDelivery /
														latest?.totalSwedenRedeemToDelivery
													)}
												</b>
											</div>
										</div>
									</Boxes>
								)}
							</div>
						)}
					</div>
				)}
			</Page>
		</>
	);
};
