import styled from 'styled-components';

export const Loading = styled.div`
	width: 100%;
	height: 100%;
	background: #fff;
	animation: fadeInLoadingScreen 200ms linear;
	animation-fill-mode: forwards;

	@keyframes fadeInLoadingScreen {
		from {
			opacity: 0;
			background-color: #fff;
		}

		to {
			opacity: 1;
			background-color: #000;
		}
	}
`;
