import styled from 'styled-components';
import React from 'react';

export const MetricBoxContainer = styled.div`
	padding: 16px;
	display: flex;
	flex-direction: column;
	background: white;
	border-radius: 5px;
	align-items: center;
	justify-content: center;
	box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
	margin: 8px;
`;
export const SubTitle = styled.h3``;
export const Title = styled.h1``;

const MetricBox = ({ subTitle, title }: { subTitle: string; title?: number }): JSX.Element => {
	return (
		<MetricBoxContainer>
			<SubTitle>{subTitle}</SubTitle>
			<Title>{title}</Title>
		</MetricBoxContainer>
	);
};

export default MetricBox;
