import { FilmState } from './Film';
import { IDelivery } from './Delivery';

export class FeedboardColumn {
	// Film related stuff
	deliveries: IDelivery[] = [];
	filmState: FilmState;

	constructor(filmState: FilmState) {
		this.filmState = filmState;
	}

	getTitle(): string {
		switch (this.filmState) {
			case FilmState.printing:
				return 'Ready for print';
			case FilmState.printingProgress:
				return 'Printed';
			case FilmState.delivered:
				return 'Delivered';
			case FilmState.sent:
				return 'Sent';
			default:
				throw new Error(
					`Title not implemented for requested filmState ${this.filmState.toString()}`
				);
		}
	}

	setDeliveries(deliveries: IDelivery[]): void {
		this.deliveries = deliveries;
	}

	removeDelivery(delivery: IDelivery): void {
		this.deliveries = this.deliveries.filter(
			d => d.deliveryInstanceId !== delivery.deliveryInstanceId
		);
	}

	async addDelivery(delivery: IDelivery): Promise<void> {
		// DO NOT REMOVE COMMENT ITS HELPFUL G'DAMNIT :)
		// Cloud function is triggering this - Update film state Firebase
		// DO NOT REMOVE COMMENT

		this.deliveries.push(delivery);
	}

	toString(): string {
		return this.filmState.toString();
	}
}
