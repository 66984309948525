import React from 'react';
import styled from 'styled-components';

const CopiedLabelStyled = styled.div`
	padding: 4px 8px;
	font-size: 11px;
	text-transform: uppercase;
	background: #eee;
	color: #666;
	border-radius: 6px;
`;

export const CopiedLabel = (): JSX.Element => {
	return <CopiedLabelStyled>Copied to clipboard</CopiedLabelStyled>;
};
