import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { IDelivery } from '../../models/Delivery';
import { AddressDisplay } from './AddressDisplay';
import Button from '../Button';
import { paddingFunction } from '../../config/Theme';
import { ProcessStatus } from '../../models/ProcessStatus';
import { LoadingIndicator } from '../LoadingIndicator';
import firebase from 'firebase/app';
import { downloadFile } from '../../../utils/downloadFile';
import { FilmState } from '../../models/Film';
import { useReactToPrint } from 'react-to-print';
import { CopyIcon } from './CopyIcon';
import { CopiedLabel } from './CopiedLabel';
import { Modal } from '../Modal';
import { FilmSendSmsForm } from './FilmSendSmsForm';
import axios from 'axios';
import { FilmLabelModal } from './FilmLabelModal';

const FilmItemStyled = styled.div`
	background: #fff;
	border: 1px solid #e5e5e5;
	padding: 16px;
	font-size: 14px;
	margin-bottom: 8px;
	border-radius: 3px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.08);

	h5 {
		margin: 0;
	}
`;

const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

const DownloadButtons = styled.div`
	display: flex;
	padding-top: 8px;
	align-items: center;

	* {
		margin-right: 4px;
	}
`;

const CopyContainer = styled.div`
	display: flex;
	align-items: center;

	> div:last-child {
		margin-left: 8px;
	}
`;

interface FilmItemProps {
	film: IDelivery;
}

const capitalize = (str: string, lower = false): string =>
	(lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());

async function openFileInNewTab(url: string) {
	const newTab = window.open(url, '_blank');
	if (newTab) {
		newTab.focus();
	} else {
		alert('Please allow popups for this website');
	}
}

const FilmItem = ({ film }: FilmItemProps): JSX.Element => {
	const [isLabelOpen, setIsLabelOpen] = useState(false);
	const [isFetchingLink, setIsFetchingLink] = useState(false);
	const [copied, setCopied] = useState(false);
	const [sendingSms, setSendingSms] = useState(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const componentRef: any = useRef();
	// @ts-ignore

	const handlePrint = useReactToPrint({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		content: () => componentRef.current as any
	});

	const downloadUrl = async (downloadPath: string) => {
		setIsFetchingLink(true);
		const userId = await firebase.auth().currentUser?.uid;

		if (userId) {
			const request = `https://us-central1-lightsnap.cloudfunctions.net/getDeliveryZipDownloadUrl?deliveryId=${film.documentId}`;

			axios
				.post(request, {
					userId,
					downloadPath
				})
				.then(response => {
					downloadFile(response.data, film.deliveryInstanceId + '.zip');
					setIsFetchingLink(false);
				})
				.catch(e => {
					// eslint-disable-next-line no-console
					console.error(e);
					alert(e);
					setIsFetchingLink(false);
				});
		} else {
			alert('Not authenticated.');
			setIsFetchingLink(false);
		}
	};
	const restartProccess = async () => {
		const userId = await firebase.auth().currentUser?.uid;

		if (userId) {
			const request = `https://us-central1-lightsnap.cloudfunctions.net/restartProccess?deliveryId=${film.documentId}`;
			axios.post(request, {
				userId
			});
		}
	};

	const copyAddressLabel = () => {
		const address = film.address;
		let str = address.name;
		str =
			str +
			`\n${address.streetName} ${address.streetNumber}, ${address.postNumber} ${address.postalTown}`;
		str = str + `\n${address.country}`;

		if (address.instructions) {
			str = str + `\n${address.instructions}`;
		}

		const el = document.createElement('textarea');
		el.value = capitalize(str);
		document.body.appendChild(el);
		el.select();
		document.execCommand('copy');
		document.body.removeChild(el);

		setCopied(true);
	};

	useEffect(() => {
		if (copied) {
			setTimeout(() => {
				setCopied(false);
			}, 3500);
		}
	}, [setCopied, copied]);

	// @ts-ignore
	return (
		<div ref={componentRef}>
			<FilmLabelModal
				open={isLabelOpen}
				closeModal={() => setIsLabelOpen(false)}
				film={film}
			/>
			<Modal open={sendingSms} title={'SendSMs'} onClose={() => setSendingSms(false)}>
				<FilmSendSmsForm
					onCancel={() => setSendingSms(false)}
					submitEvent={data => {
						return axios
							.post(
								'https://us-central1-lightsnap.cloudfunctions.net/sendFilmReminderSms',
								{
									userId: film.userId,
									message: data.smsContent
								}
							)
							.then(response => {
								// eslint-disable-next-line no-console
								console.log(response, 'response');
							})
							.catch(e => {
								// eslint-disable-next-line no-console
								console.error(e);
								alert(e);
							});
					}}
				/>
			</Modal>
			<FilmItemStyled>
				<div
					style={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'space-between',
						marginBottom: paddingFunction(2)
					}}
				>
					<div>
						<h5>{film.title || 'No film title'}</h5>
						<small>{film.documentId}</small>
					</div>
					<CopyContainer>
						{copied && <CopiedLabel />}
						<CopyIcon size={24} onClick={copyAddressLabel} />
					</CopyContainer>
				</div>
				<AddressDisplay
					address={film.address}
					border={true}
					padding={true}
					showTitles={true}
				/>
				<Buttons>
					<DownloadButtons>
						{film.state !== FilmState.sent && (
							<div>
								{film.processing === ProcessStatus.DONE && (
									<Button
										loading={isFetchingLink}
										onClick={(): void => {
											if (
												film.download &&
												film.download.startsWith('https://')
											) {
												//
												openFileInNewTab(film.download);
											} else {
												downloadUrl(
													`${film.userId}/${film.filmId}/${film.documentId}.zip`
												);
											}
										}}
									>
										⬇️&nbsp;&nbsp;Files
									</Button>
								)}
								{film.processing === ProcessStatus.PROCESSING && (
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<LoadingIndicator scale={0.7} />
										<p style={{ marginLeft: 16 }}>Currently processing</p>
										<Button
											loading={isFetchingLink}
											onClick={(): void => {
												restartProccess();
											}}
										>
											Ree
										</Button>
									</div>
								)}
								{!film.processing && <p>Delivery not ready yet</p>}
							</div>
						)}
						{film.state !== FilmState.sent && (
							<>
								<div>
									<Button onClick={handlePrint}>🖨&nbsp;&nbsp;Paper</Button>
								</div>
								<div>
									<Button onClick={() => setIsLabelOpen(true)}>
										🖨&nbsp;&nbsp;Label
									</Button>
								</div>
							</>
						)}
						{film.state === FilmState.sent && (
							<div>
								<Button onClick={() => setSendingSms(true)}>Send sms</Button>
							</div>
						)}
					</DownloadButtons>
					{film.state !== FilmState.sent && film.state !== FilmState.printingProgress ? (
						<DownloadButtons>
							<div style={{ marginRight: 0 }}>
								<Button
									appearance={'inverted-border'}
									onClick={() => {
										if (film.state === FilmState.printing) {
											film.setState(FilmState.printingProgress);
										} else if (film.state === FilmState.printingProgress) {
											film.setState(FilmState.sent);
										}
									}}
								>
									✅&nbsp;&nbsp;
									{film.state === FilmState.printing
										? 'Mark as printed'
										: 'Mark as sent'}
								</Button>
							</div>
						</DownloadButtons>
					) : (
						<DownloadButtons>
							<div style={{ marginRight: 0 }}>
								<Button
									appearance={'inverted-border'}
									onClick={() => film.setState(FilmState.printing)}
								>
									⬅️&nbsp;&nbsp;Print again
								</Button>
							</div>
						</DownloadButtons>
					)}
				</Buttons>
			</FilmItemStyled>
		</div>
	);
};

export default FilmItem;
