import React from 'react';
import 'firebase/firestore';
import axios from 'axios';
import Page from '../../components/Page';

const Notifications = () => {
	const sendNotification = () => {
		axios.get('https://us-central1-lightsnap.cloudfunctions.net/sendNotification');
	};
	return (
		<Page>
			<button onClick={() => sendNotification()}>SENDING NOTIFICATION</button>
		</Page>
	);
};
export default Notifications;
