import React, { useEffect, useRef } from 'react';
import { Modal } from '../Modal';
import { FilmLabel } from './FilmLabel';
import { IDelivery } from '../../models/Delivery';
import Button from '../Button';
import { useReactToPrint } from 'react-to-print';

interface FilmLabelModalProps {
	open: boolean;
	closeModal: () => void;
	film: IDelivery;
}

export const FilmLabelModal = ({ open, closeModal, film }: FilmLabelModalProps): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const labelRef: any = useRef();

	const print = useReactToPrint({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		content: () => labelRef.current as any
	});

	useEffect(() => {
		if (labelRef.current && print) {
			print();
			closeModal();
		}
	}, [labelRef, print]);

	return (
		<Modal open={open} onClose={() => closeModal()}>
			<div ref={labelRef} style={{ width: '96mm', height: '56mm' }}>
				<FilmLabel delivery={film} />
			</div>
			<div style={{ display: 'flex' }}>
				<Button onClick={print}>Print</Button>
				<div style={{ width: 16 }} />
				<Button onClick={() => closeModal()}>Close</Button>
			</div>
		</Modal>
	);
};
