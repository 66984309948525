"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "getTickValues", {
  enumerable: true,
  get: function get() {
    return _getNiceTickValues.getTickValues;
  }
});
Object.defineProperty(exports, "getNiceTickValues", {
  enumerable: true,
  get: function get() {
    return _getNiceTickValues.getNiceTickValues;
  }
});
Object.defineProperty(exports, "getTickValuesFixedDomain", {
  enumerable: true,
  get: function get() {
    return _getNiceTickValues.getTickValuesFixedDomain;
  }
});

var _getNiceTickValues = require("./getNiceTickValues");