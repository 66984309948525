import React from 'react';
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, BarChart } from 'recharts';
import { MetricBoxContainer, Title } from './MetricBox';

interface IBarData {
	name: string;
	value1: number;
}
const LBarChart = ({
	data,
	title
}: {
	data: IBarData[];
	title: string;
	minValue?: number;
}): JSX.Element => {
	return (
		<MetricBoxContainer>
			<Title>{title}</Title>
			<BarChart width={400} height={300} data={data}>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis />
				<Tooltip />
				<Legend />
				<Bar dataKey="value1" fill="#8884d8" />
			</BarChart>
		</MetricBoxContainer>
	);
};

export default LBarChart;
