import React from 'react';
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { MetricBoxContainer, Title } from './MetricBox';

interface IChartData {
	name: string;
	value: number;
}
const LineChart = ({
	data,
	title,
	minValue,
	subTitle
}: {
	data: IChartData[];
	title: string;
	minValue?: number;
	subTitle?: string | number;
}): JSX.Element => {
	return (
		<MetricBoxContainer>
			<Title>{title}</Title>
			{subTitle && <span>{subTitle}</span>}

			<AreaChart
				width={400}
				height={300}
				data={data}
				margin={{
					top: 10,
					right: 30,
					left: 0,
					bottom: 0
				}}
			>
				<CartesianGrid strokeDasharray="3 3" />
				<XAxis dataKey="name" />
				<YAxis domain={[minValue || 'auto', 'auto']} />
				<Tooltip />
				<Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
			</AreaChart>
		</MetricBoxContainer>
	);
};

export default LineChart;
