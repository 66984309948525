import React, { useEffect, useRef } from 'react';
import { Modal } from '../Modal';
import { useReactToPrint } from 'react-to-print';
import { LightsnapPrintableLabel } from '../Lightsnap';

interface FilmLabelModalProps {
	open: boolean;
	closeModal: () => void;
}

export const LightsnapLabelModal = ({ open, closeModal }: FilmLabelModalProps): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const labelRef: any = useRef();

	const print = useReactToPrint({
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		content: () => labelRef.current as any
	});

	useEffect(() => {
		if (labelRef.current && print) {
			print();
			closeModal();
		}
	}, [labelRef, print]);

	return (
		<Modal open={open} onClose={() => closeModal()}>
			<div ref={labelRef} style={{ width: '62mm', height: '27mm' }}>
				<LightsnapPrintableLabel />
			</div>
		</Modal>
	);
};
