import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../components/Button';
import Page from '../../components/Page';

const NotFound = (): JSX.Element => {
	return (
		<Page>
			<h1>Not Found</h1>
			<Link to={'/'}>
				<Button>Take me back</Button>
			</Link>
		</Page>
	);
};

export default NotFound;
