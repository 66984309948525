import React, { useContext } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { AppContext } from '../Darkroom';
import { Lightsnap } from './Lightsnap';

interface PageProps {
	children: React.ReactNode;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	pageContentProps?: any;
}

const PageContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100vw;
	height: 100vh;
	overflow: hidden;
`;

const NavigationBar = styled.div`
	flex: 0;
	background: #000;
	color: #fff;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	text-align: center;
	padding: ${(props): string => props.theme.padding(3) + ' ' + props.theme.padding(4)};

	a {
		position: relative;
		top: 2px;
		color: #fff;
		font-weight: 600;
		font-size: 16px;
		transition: all 0.2s linear;
		opacity: 1;
		display: inline-block;
		padding-bottom: 4px;
		border-bottom: 2px solid transparent;

		&:hover {
			opacity: 0.5;
			border-bottom-color: transparent;
		}

		&.active {
			border-bottom-color: ${(props): string => props.theme.colors.primary};
		}
	}
`;

const Links = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;

	img {
		margin-right: ${(props): string => props.theme.padding(4)};
	}

	> div:not(:last-child) {
		margin-right: ${(props): string => props.theme.padding(4)};
	}
`;

const PageContent = styled.div`
	flex: 1;
	padding: 0 ${(props): string => props.theme.padding(4)};
	overflow-y: auto;
`;

const PageRoute = ({ route, text }: { route: string; text: string }): JSX.Element => {
	const location = useLocation();

	return (
		<Link to={route} className={location.pathname === route ? 'active' : ''}>
			{text}
		</Link>
	);
};

const Page = ({ children, pageContentProps, ...props }: PageProps): JSX.Element => {
	const appContext = useContext(AppContext);

	const showMore = () => {
		if (
			appContext?.email === 'geb.2991@gmail.com' ||
			appContext?.email === 'dev@lightsnap.app' ||
			appContext?.email === 'togga@lightsnap.app'
		) {
			return true;
		}
		return false;
	};
	return (
		<PageContainer {...props}>
			<NavigationBar>
				<Links>
					<Lightsnap color={'#fff'} scale={0.14} />
					<div style={{ marginLeft: 36 }}>
						<PageRoute route={'/admin'} text={'Films'} />
					</div>
					{showMore() && (
						<>
							<div>
								<PageRoute route={'/admin/events'} text={'Events'} />
							</div>
							<div>
								<PageRoute route={'/admin/queues'} text={'Queues'} />
							</div>
							<div>
								<PageRoute route={'/admin/gifts'} text={'Gifts'} />
							</div>
							{/*<div>
								<PageRoute route={'/admin/notifications'} text={'Notifications'} />
							</div>*/}
							<div>
								<PageRoute route={'/admin/metrics'} text={'Metrics'} />
							</div>
						</>
					)}
				</Links>
				<div style={{ flex: 1 }} />
				<div>
					<Link to={'/logout'}>Logout</Link>
				</div>
			</NavigationBar>
			<PageContent {...pageContentProps}>{children}</PageContent>
		</PageContainer>
	);
};

export default Page;
