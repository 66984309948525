import React from 'react';
import styled from 'styled-components';
// @ts-ignore
import { FieldElement, FieldError, Ref, ValidationRules } from 'react-hook-form/dist/types/form';

interface InputWithLabelProps {
	name: string;
	label: string;
	type?: 'text' | 'number' | 'email' | 'date' | 'datetime-local';
	register<TFieldElement extends FieldElement<HTMLInputElement>>(
		rules?: ValidationRules
	): (ref: (TFieldElement & Ref) | null) => void;
	error?: FieldError;
	required?: boolean;
	min?: number;
	max?: number;
}

const InputWithLabelStyled = styled.div`
	> label {
		display: inline-block;
		font-size: 14px;
		font-weight: bold;
		margin-bottom: 12px;
		cursor: pointer;
	}

	> div > input {
		width: 100%;
		border: 1px solid #e5e5e5;
		outline: none;
		padding: 12px;

		&:focus {
			border-color: ${props => props.theme.colors.orange};
		}
	}

	> span {
		display: block;
		margin-top: 8px;
		font-size: 12px;
		color: red;
	}
`;

export const InputWithLabel = ({
	name,
	label,
	error,
	register,
	required = false,
	...props
}: InputWithLabelProps): JSX.Element => {
	return (
		<InputWithLabelStyled>
			{label && <label htmlFor={name}>{label}</label>}
			<div>
				<input name={name} id={name} ref={register({ required })} {...props} />
			</div>
			{error && <span>This field is invalid</span>}
		</InputWithLabelStyled>
	);
};
