import React from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { Spinner } from './Loading/Spinner';

type ButtonAppearance = 'normal' | 'inverted' | 'inverted-border';

interface ButtonProps {
	children: React.ReactNode;
	width?: string;
	appearance?: ButtonAppearance;
	loading?: boolean;
	disabled?: boolean;
	type?: 'button' | 'submit';
	onClick?(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
}

const StyledButton = styled.button<Pick<Required<ButtonProps>, 'width' | 'appearance'>>`
	background: #000000;
	color: #fff;
	padding: 12px 24px;
	outline: none;
	border: none;
	cursor: pointer;
	width: ${(props): string => props.width};
	border-radius: ${(props): string => props.theme.borderRadius};
	transition: all 0.2s linear;
	transform: scale(1);
	font-weight: bold;

	&:active {
		transform: scale(0.98);
	}

	${(props): FlattenSimpleInterpolation => {
		switch (props.appearance) {
			case 'normal': {
				return css`
					background: #000;
					color: #fff;
				`;
			}
			case 'inverted': {
				return css`
					background: #fff;
					color: #000;
				`;
			}
			case 'inverted-border': {
				return css`
					background: #fff;
					color: #000;
					border: 1px solid #e5e5e5;

					&:hover {
						background-color: #fbfbfb;
						border-color: #000;
					}
				`;
			}
			default:
				throw new Error('Invalid appearance for Button');
		}
	}}
`;

const Button = ({
	children,
	width = 'auto',
	appearance = 'normal',
	loading = false,
	disabled = false,
	...props
}: ButtonProps): JSX.Element => (
	<StyledButton appearance={appearance} width={width} disabled={disabled || loading} {...props}>
		{loading ? (
			<Spinner size={20} color={appearance === 'normal' ? '#fff' : '#000'} />
		) : (
			children
		)}
	</StyledButton>
);

export default Button;
