import React from 'react';
import Button from '../../components/Button';
import styled from 'styled-components';
import FormInput from '../../components/FormInput';
import { useCallback, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

const FormContainer = styled.div`
	margin-top: ${(props): string => props.theme.padding(10)};
`;

const InputContainer = styled.div`
	margin-bottom: ${(props): string => props.theme.padding(4)};
`;

const LoginForm = (): JSX.Element => {
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');

	const handleSubmit = useCallback((): void => {
		setIsSubmitting(true);

		firebase
			.auth()
			.signInWithEmailAndPassword(email, password)
			.then(() => {
				setIsSubmitting(false);
				window.location.href = '/admin/';
			})
			.catch(error => {
				//console.error(error.code);
				//console.error(error.message);
				setIsSubmitting(false);

				// TODO fix
				alert(error.message);
			});
	}, [email, password]);

	return (
		<FormContainer>
			<form
				method={'post'}
				onSubmit={(event): void => {
					event.preventDefault();
					handleSubmit();
				}}
			>
				<InputContainer>
					<FormInput
						name={'email'}
						type={'email'}
						label={'E-mail address'}
						placeholder={'Enter your email address'}
						width={'100%'}
						appearance={'inverted'}
						value={email}
						onChange={(event): void => {
							setEmail(event.target.value);
						}}
					/>
				</InputContainer>
				<InputContainer>
					<FormInput
						name={'password'}
						type={'password'}
						label={'Password'}
						placeholder={'Type in your password'}
						width={'100%'}
						appearance={'inverted'}
						value={password}
						onChange={(event): void => {
							setPassword(event.target.value);
						}}
					/>
				</InputContainer>
				<div>
					<Button
						width={'100%'}
						appearance={'inverted'}
						onClick={handleSubmit}
						loading={isSubmitting}
					>
						Login
					</Button>
				</div>
			</form>
		</FormContainer>
	);
};

export default LoginForm;
