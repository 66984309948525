import React from 'react';
import styled from 'styled-components';
import LoginForm from './LoginForm';

const LoginContainer = styled.div`
	background: #000;
	width: 100%;
	height: 100%;
	box-shadow: 0 50px 150px 25px rgba(0, 0, 0, 0.2);
	color: #fff;
	display: flex;

	a {
		color: #fff;
	}
`;

const LoginFormContainer = styled.div`
	flex: 0;
	padding: ${(props): string => props.theme.padding(10)};
	flex-basis: 520px;
`;

const LoginFormBackground = styled.div`
	flex: 1;
	height: 100%;
	background: url(../../../assets/bg_tunnel.jpg);
	background-size: cover;
`;

const Login = (): JSX.Element => {
	return (
		<LoginContainer>
			<LoginFormContainer>
				<div>
					<img src={'../../../assets/ls-icon.svg'} alt={'LightSnap Inc.'} />
				</div>
				<LoginForm />
			</LoginFormContainer>
			<LoginFormBackground />
		</LoginContainer>
	);
};

export default Login;
