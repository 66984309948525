import React, { useMemo, useState } from 'react';
import { FeedboardColumn } from '../../models/FeedboardColumn';
import FilmItem from '../../components/FeedBoard/FilmItem';
import { FeedTableTab, FeedTableTabs } from './FeedTableTab';
import { TextField } from '@material-ui/core';
import { SearchIcon } from '../../components/SearchIcon';
import Button from '../../components/Button';
// @ts-ignore
import AutoSizer from 'react-virtualized/dist/commonjs/AutoSizer';
// @ts-ignore
import List from 'react-virtualized/dist/commonjs/List';
interface FeedTableProps {
	columns: FeedboardColumn[];
	downloadImage: () => void;
}

export const FeedTable = ({ columns, downloadImage }: FeedTableProps): JSX.Element => {
	const [search, setSearch] = useState('');
	const [tab, setTab] = useState<FeedboardColumn>(columns[0]);

	const deliveries = useMemo(() => {
		let dels = tab.deliveries;

		if (search && search.length > 0) {
			const str = search.toLowerCase();
			dels = dels.filter(
				d =>
					d.title.toLowerCase().includes(str) ||
					JSON.stringify(d.address)
						.toLowerCase()
						.includes(str)
			);
		}

		return dels.filter(d => d.state === tab.filmState);
	}, [tab.deliveries, tab.filmState, search]);

	const rowRenderer = ({ index, key, style }: never) => {
		return (
			<div key={key} style={style}>
				<FilmItem film={deliveries[index]} key={index} />
			</div>
		);
	};
	return (
		<>
			<FeedTableTabs>
				{columns.map((c, cIndex) => (
					<FeedTableTab
						downloadImage={downloadImage}
						active={tab.filmState === c.filmState}
						key={cIndex}
						column={c}
						onClick={() => setTab(c)}
					/>
				))}
				<div style={{ flex: 1 }} />
				<div>
					<TextField
						label={'Search'}
						onChange={ev => setSearch(ev.target.value)}
						InputProps={{
							startAdornment: (
								<div>
									<SearchIcon size={14} color={'#000'} />
								</div>
							)
						}}
					/>
				</div>
			</FeedTableTabs>
			<div>
				{tab.getTitle() === 'Printed' && (
					<Button onClick={() => downloadImage()}>Print póstur miði</Button>
				)}

				<AutoSizer disableHeight>
					{({ width }: never) => (
						<List
							height={800}
							rowCount={deliveries.length}
							rowHeight={320}
							rowRenderer={rowRenderer}
							width={width}
						/>
					)}
				</AutoSizer>
			</div>
		</>
	);
};
