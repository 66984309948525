import React from 'react';
import { IDelivery } from '../../models/Delivery';
import { AddressDisplay } from './AddressDisplay';
import { Lightsnap } from '../Lightsnap';
import styled from 'styled-components';

interface FilmLabelProps {
	delivery: IDelivery;
}

const LabelContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	padding-top: 14px;
`;

const LabelFooter = styled.p`
	font-size: 10pt;
	margin: 0;
	padding: 0;
	line-height: 24px;
`;

const Line = () => <div style={{ width: '100%', height: 1, background: '#000' }} />;

export const FilmLabel = ({ delivery }: FilmLabelProps): JSX.Element => (
	<LabelContainer>
		<Lightsnap color={'#000'} scale={0.2} />
		<Line />
		<AddressDisplay
			padding={false}
			address={delivery.address}
			showTitles={false}
			border={false}
			isLabel={true}
		/>
		<Line />
		<LabelFooter>
			Return to: Katrinartun 4, 101 Reykjavik, Iceland
			<br />
			contact@lightsnap.app - Lightsnap, Inc.
		</LabelFooter>
	</LabelContainer>
);
