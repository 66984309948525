import React, { useState } from 'react';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { InputWithLabel } from '../../../components/InputWithLabel';
import Button from '../../../components/Button';
import { IEvent } from '../../../models/Event';

interface EventFormProps {
	onCancel(): void;
	submitEvent(data: IEvent): Promise<void>;
}

const EventFormStyled = styled.form`
	> div:not(:last-child) {
		margin-bottom: 24px;
	}
`;

const FormButtons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const EventForm = ({ onCancel, submitEvent }: EventFormProps): JSX.Element => {
	const { register, handleSubmit, errors } = useForm<IEvent>();
	const [loading, setLoading] = useState(false);

	const onSubmit = async (data: IEvent) => {
		setLoading(true);
		data.public = Boolean(data.public);
		data.created = new Date().toISOString();
		data.users = [];
		// @ts-ignore
		data.photosPerUser = parseInt(data.photosPerUser);
		if (data.endDate) {
			data.endDate = data.endDate.toString();
		}
		await submitEvent(data);
		setLoading(false);
		onCancel();
	};

	return (
		<div>
			<EventFormStyled onSubmit={handleSubmit(onSubmit)}>
				<div>
					<InputWithLabel
						error={errors.title}
						name={'title'}
						label={'Event name'}
						register={register}
						required={true}
					/>
				</div>
				<div>
					<InputWithLabel
						error={errors.eventCode}
						name={'eventCode'}
						label={'Event code'}
						register={register}
						required={true}
					/>
				</div>
				<div>
					<InputWithLabel
						error={errors.photosPerUser}
						name={'photosPerUser'}
						type={'number'}
						label={'Photos per user'}
						register={register}
						required={true}
						min={1}
						max={1000}
					/>
				</div>
				<div>
					<InputWithLabel
						error={errors.endDate}
						name={'endDate'}
						type={'datetime-local'}
						label={'EndDate'}
						register={register}
					/>
				</div>
				<div>
					<input name={'public'} defaultValue={'true'} type={'hidden'} ref={register} />
				</div>

				<FormButtons>
					<Button type={'submit'} loading={loading}>
						Create event
					</Button>
					<Button
						appearance={'inverted-border'}
						type={'button'}
						onClick={() => onCancel()}
					>
						Cancel
					</Button>
				</FormButtons>
			</EventFormStyled>
		</div>
	);
};
